import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The new version 2.2 of the `}<ItalicText mdxType="ItalicText">{`Web Content Accessibility Guidelines`}</ItalicText>{` was released recently.
It adds 9 new success criteria and removes the old `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/parsing.html"
      }}>{`4.1.1 Parsing`}</a>{`
criterion.`}</p>
    <p>{`I won't bore you with another `}<ItalicText mdxType="ItalicText">{`“WCAG 2.2 is finally here!”`}</ItalicText>{` article. Other accessibility experts with
more experience than me have already explained the changes compared to WCAG 2.1 (see the link section at the end). Instead,
I'd like to focus on some of the new criteria and give you practical examples of how to meet them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFj4KC1Bgn/xAAbEAABBAMAAAAAAAAAAAAAAAABAAIDBBEiMv/aAAgBAQABBQKeXAhILVZbpW4X/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/ATF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhBR/9oACAEBAAY/Al2VICGOUz//xAAbEAABBAMAAAAAAAAAAAAAAAAAAREhMRBBYf/aAAgBAQABPyG3NQShKRGxhzmpSx//2gAMAwEAAgADAAAAEC8//8QAFxEBAAMAAAAAAAAAAAAAAAAAARAhUf/aAAgBAwEBPxBNNj//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EDW4/8QAHhABAAICAQUAAAAAAAAAAAAAAQARIUExUXGBkaH/2gAIAQEAAT8QzZDVfG4ZTcLZaX77lesAk5xHeXSNK8+YgKE//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A woman's face partially hidden behind a book with a green cover.",
          "title": "A woman's face partially hidden behind a book with a green cover.",
          "src": "/static/d9427ba0d382870163ce8912729e1ee3/e5166/pexels-george-milton-hiding-behind-book.jpg",
          "srcSet": ["/static/d9427ba0d382870163ce8912729e1ee3/f93b5/pexels-george-milton-hiding-behind-book.jpg 300w", "/static/d9427ba0d382870163ce8912729e1ee3/b4294/pexels-george-milton-hiding-behind-book.jpg 600w", "/static/d9427ba0d382870163ce8912729e1ee3/e5166/pexels-george-milton-hiding-behind-book.jpg 1200w", "/static/d9427ba0d382870163ce8912729e1ee3/b17f8/pexels-george-milton-hiding-behind-book.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © George Milton / pexels.com`}</em></p>
    <h2>{`The New Criteria of WCAG 2.2`}</h2>
    <p>{`Several new criteria aim to improve the operability of websites and apps: Focus indicators should be clearly perceivable and the
focused control should not be obscured by other content on the page.`}</p>
    <p>{`Controls should be large enough so they can be easily activated without accidentally activating an adjacent target. If a site has
functionality that uses a dragging movement for operation, then it must offer an alternative way for mouse and keyboard users.`}</p>
    <p>{`Here's a list of all new success criteria that relate to operability:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-minimum.html"
        }}>{`2.4.11 Focus Not Obscured (Minimum) (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-enhanced.html"
        }}>{`2.4.12 Focus Not Obscured (Enhanced) (AAA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance.html"
        }}>{`2.4.13 Focus Appearance (AAA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements.html"
        }}>{`2.5.7 Dragging Movements (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html"
        }}>{`2.5.8 Target Size (Minimum) (AA)`}</a></li>
    </ul>
    <p>{`The following success criteria aim to improve accessibility for people with cognitive and learning disabilities:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/consistent-help.html"
        }}>{`3.2.6 Consistent Help (A)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/redundant-entry.html"
        }}>{`3.3.7 Redundant Entry (A)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-minimum.html"
        }}>{`3.3.8 Accessible Authentication (Minimum) (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-enhanced.html"
        }}>{`3.3.9 Accessible Authentication (Enhanced) (AAA)`}</a></li>
    </ul>
    <p>{`Now let's look at some practical examples for the new success criteria.`}</p>
    <h2>{`Practical Examples`}</h2>
    <h3>{`Use Case: Sticky Page Header`}</h3>
    <p>{`Many websites include a header that sticks to the top of the page, even when the page is scrolled. If done right, this sticky header
improves the usability of the website. Imagine a user reading a long article: When they arrive at the bottom of the page, they don't
have to scroll back up to access the main navigation in the header.`}</p>
    <p>{`Unfortunately, a sticky header can hurt the experience of sighted people who can't use a mouse. When they navigate a website using
the keyboard, they always need to see what has keyboard focus. A sticky header or footer can lead to focused elements disappearing
behind these elements.`}</p>
    <p>{`Lucky for us, there's a simple and elegant solution for this problem: The CSS property `}<InlineCode mdxType="InlineCode">{`scroll-padding`}</InlineCode>{` enables
us to define offsets for the optimal viewing region of the scrollport — the region used as the target region for placing things in view
of the user. All you need is one line of CSS:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`html {
    scroll-padding: 4rem 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Here's a demo of a website with a sticky header and footer. Try navigating with the tab key and pay attention to the browser keeping
the focused link in view:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/scrollpadding" title="Scroll Padding Demo" loading="lazy"></iframe>
    <p>{`This way, the website conforms to `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-minimum.html"
      }}>{`2.4.11 Focus Not Obscured (Minimum) (AA)`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-enhanced.html"
      }}>{`2.4.12 Focus Not Obscured (Enhanced) (AAA)`}</a>{`.`}</p>
    <p>{`Another related CSS property is `}<InlineCode mdxType="InlineCode">{`scroll-margin`}</InlineCode>{`. You can use it to, e.g., un-obscure content underneath a
fixed-position banner, as the WCAG technique C43 demonstrates:
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Techniques/css/C43"
      }}>{`“Using CSS margin and scroll-margin to un-obscure content”`}</a>{`.`}</p>
    <h3>{`Use Case: Interactive Map with Drag to Move`}</h3>
    <p>{`An interactive map usually allows the user to drag the view of the map with the mouse or on touch. But some people cannot perform
dragging movements in a precise manner. Or they use a specialized input device, such as a trackball, or head pointer, which may make
dragging cumbersome and error-prone.`}</p>
    <p>{`An accessible map should also include up/down/left/right buttons to move the view, and conform to the success criterion
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements.html"
      }}>{`2.5.7 Dragging Movements (AA)`}</a>{`. Users with motor impairments can
easily tab to the controls and use them to pan the map. Check out my `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/cascade-layers-angular-demo/"
      }}>{`demo`}</a>{`
of a map with zoom and pan buttons:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "852px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWUlEQVQozyWSuXLaCABA9f/VNimzW8TjA6/PxIDxosNCgITQfSEJXYAFgiSTmSTt2/Fu+Zo3r3jCoduRFRHbOqXdNGxzB/+twNx9Y7HpsDYn0oOFvQ9wNkf2ocVXVeEoSRxil7CL2XUVVVtz+vEdIYx8JvMnktzCnKvYkyGa/kgvTDlbZpy5G+5sEVn5wMtYQ5m+orz+xfTxT/zrG/rZE1m1RHEHlMcVQlFk+K6O/eYSbR3KJGG9FNG8Eb244tIIGIkqptXnOV1w7r0xXutMKhX/4ZHcnpNsQ0z5I+0hQVgYExxTwpqMMQONKJhRhh6e+oAoDXl+HnGlGlyFa4aRxK3rcuMVvMYxUuywFr9QxR7T1RPlKUAwFyrOVCZQZTxzjmVJNHVG7WrED38wij0uoh09t+TOi5gWEjdBzq1XcB9n6KshrSwjexJ5lyEE7hBHV/EdHd8yqLIVUWLQOBKVcoZp9vmyyrlb5ly6FS+Jh5ppXHnFf/yYx0TqkER6Zfv9iOBZc/T5GGO6IG9TVk2As9DwHI06S8jsAaIzoBcn/0u8mufI4CWV6fkVF25NP4lJ531+HbYIjmEReCKWOSTwF2Spg2Vo2EuFJFVZZTqO+RnRVriNNlz5Jdd+xbhQ+Sd/L23ohTUTx+TnfouwnM4ILBfTuGemDNAVGWM2wl0MkOZ/Y8UzwsJm8vmae1nhwi/5ZOec2xliNuYpNjmzS87tiPbbEWFXNnSbjqbMWecm6zSi3QU0RYif6VR1wunQ0ZYpkTPD3h6YNu/DH8i7Pc1pQ/62J2lafvz8zb93dquwo+uAegAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot of an interactive map that has up/down/left/right buttons to move the view.",
          "title": "Screenshot of an interactive map that has up/down/left/right buttons to move the view.",
          "src": "/static/298f12c58d9a122c618d2f1de28f882f/47ff6/screenshot-map-with-move-controls.png",
          "srcSet": ["/static/298f12c58d9a122c618d2f1de28f882f/5a46d/screenshot-map-with-move-controls.png 300w", "/static/298f12c58d9a122c618d2f1de28f882f/0a47e/screenshot-map-with-move-controls.png 600w", "/static/298f12c58d9a122c618d2f1de28f882f/47ff6/screenshot-map-with-move-controls.png 852w"],
          "sizes": "(max-width: 852px) 100vw, 852px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Alexander Lehner`}</em></p>
    <p>{`As the screenshot above shows, the focused button is clearly highlighted with a thick outline and color change. Continue to the next
section to learn more about accessible focus indicators.`}</p>
    <h3>{`Use Case: Clearly Visible Focus Indicators`}</h3>
    <p>{`Providing visible focus indicators is a basic feature of accessible websites and apps. The success criterion
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible.html"
      }}>{`2.4.7 Focus Visible (AA)`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Any keyboard operable user interface has a mode of operation where the `}<strong>{`keyboard focus indicator is visible`}</strong>{`.`}</p>
    </blockquote>
    <p>{`This criterion has been around for almost 15 years, ever since WCAG 2.0 was released. Unfortunately, there's still lots of
websites with no or barely visible focus indicators. One of the reasons might be that WCAG never normatively defined what “visible”
meant in practice.`}</p>
    <p>{`The new sucess criterion `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance.html"
      }}>{`2.4.13 Focus Appearance (AAA)`}</a>{` finally
defines the minimum dimensions and appearance for focus indicators. They should cover enough space and provide a contrast of at least
3:1 between an element's focused and unfocused state.`}</p>
    <p>{`Often, a client or designer might oppose strong focus indicators for aesthetic reasons. In this case, I recommend the use of
the `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` CSS pseudo-class. It lets you show focus styles only when they are needed (e.g., on
keyboard navigation). Check out my demo that compares `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{` to `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{`.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/a11yfeatures" title="Demo for the focus-visible pseudo-class" loading="lazy"></iframe>
    <p>{`If you want to go into more details, read my post
`}<a parentName="p" {...{
        "href": "/highly-visible-keyboard-focus.en/"
      }}>{`“Provide highly visible Keyboard Focus with :focus-visible”`}</a>{`.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG22/"
        }}>{`Web Content Accessibility Guidelines (WCAG) 2.2, W3C Recommendation, 05 October 2023`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tetralogical.com/blog/2023/10/05/whats-new-wcag-2.2/"
        }}>{`What's new in WCAG 2.2 (TetraLogical)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.levelaccess.com/blog/wcag-22-aa-summary-and-checklist-for-website-owners/"
        }}>{`WCAG 2.2 AA: Summary and Checklist for Website Owners (Level Access)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-padding"
        }}>{`MDN: CSS scroll-padding`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible"
        }}>{`MDN: CSS :focus-visible`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      